export default defineNuxtRouteMiddleware(() => {
  const cookie = useCookie('applicant-token');
  const sessionStore = useSessionStore();

  if (cookie.value === undefined || cookie.value === null || !sessionStore.timerActive) {
    sessionStore.setNotAuthRedirect(true);
    // @ts-ignore
    return (window.location = '/');
  }
});
